export const firebaseConfig = {
  apiKey: "AIzaSyChxpRW_bPtyjD6WlHZ-tM3xSlqSpiVmxI",
  authDomain: "digmtime-ea1a2.firebaseapp.com",
  databaseURL: "https://digmtime-ea1a2-default-rtdb.firebaseio.com",
  projectId: "digmtime-ea1a2",
  storageBucket: "digmtime-ea1a2.appspot.com",
  messagingSenderId: "884416023225",
  appId: "1:884416023225:web:af247cbebb89813ccba968",
  measurementId: "G-M1J6SFE9QD"
};


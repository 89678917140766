import React, { useState, useEffect } from "react";

const SignUpButton = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const mobileButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3fa1cf",
    cursor: "pointer",
    width: "100%", /* Default full-width for mobile */
    marginBottom: "60px",
    marginTop: 60,
    textAlign: "center",
    fontSize: "2em",
    color: "white",
    textShadow: "0px 4px 8px #060a13",
    transition: "background-color 0.3s",
    padding: '30px',
    fontWeight: 1000
  };

  const PCButtonStyle = {
    width: "50%",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3fa1cf",
    cursor: "pointer",
    marginBottom: "60px",
    marginTop: 60,
    textAlign: "center",
    fontSize: "2.5em",
    fontWeight: "700",
    color: "white",
    textShadow: "0px 4px 8px #060a13",
    padding: "50px",
    transition: "background-color 0.3s",
  };

  const mobileHoverStyle = {
    backgroundColor: "#30D5C8",
  };

  const PCHoverStyle = {
    backgroundColor: "#30D5C8",
  };

  const buttonStyle = windowWidth >= 768 ? { ...PCButtonStyle } : { ...mobileButtonStyle };

  const hoverStyle = windowWidth >= 768 ? PCHoverStyle : mobileHoverStyle;

  const handleHoverEnter = () => {
    setHovered(true);
  };

  const handleHoverLeave = () => {
    setHovered(false);
  };

  return (
    <div style={containerStyle}>
      <a
        href={"/signup"}
        style={{
          ...buttonStyle,
          ...(isHovered ? hoverStyle : null),
        }}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
      >
        Sign Up Here
      </a>
    </div>
  );
};

export default SignUpButton;

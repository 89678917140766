import React from "react";
import HeaderMenu from './HeaderMenu';
import './Header.css';

const Header =()=>{
  return (
    <div className="TotalHeaderBox">
      <div>
        <HeaderMenu/>
      </div>
    </div>
  )
}; 

export default Header;
import React, {useState} from "react";
import './SignUp.css';
import TextArea from "../components/textarea/TextArea";
import Select from "../components/select/Select";

const SignUp =()=>{

  const [newUserObject, setNewUserObject] = useState('');

  const handleTextChange = (key, value) => {
    setNewUserObject({...newUserObject, [key]: value});
  };

  const handleStateChange = (event) => {
    setNewUserObject({...newUserObject, 'state': event.target.value});
  };

  console.log(newUserObject)
  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  return (
    <div className="SignUpContainer">
      <h1 className="SectionHeaderText" styles={{textAlign: "center"}}>Sign Up</h1>
      <div>
        <div className="BodyText">
          <label className="BodyText" style={{flexDirection: 'row', marginBottom: "1em", fontSize: "1em", alignSelf: "center"}}>Already a member?&nbsp;&nbsp;&nbsp;<a href={"/signin"}>Click Here</a></label>
        <TextArea className="SignUpInput TextAreaText" placeholder="First Name" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Last Name" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Email" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Password" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Confirm Password" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Phone Number" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Address" onTextChange={handleTextChange}></TextArea>
        <Select states={states} placeholder="Select a State" handleStateChange={handleStateChange}/>
        <TextArea className="SignUpInput TextAreaText" placeholder="City" onTextChange={handleTextChange}></TextArea>
        <TextArea className="SignUpInput TextAreaText" placeholder="Zip" onTextChange={handleTextChange}></TextArea>
        </div>
        <button className="SignUpButton">Sign Up</button>
      </div>

    </div> 
    
  )
};

export default SignUp;
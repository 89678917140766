import './App.css';

import { BrowserRouter as Router } from "react-router-dom";
import MyRoutes from './routes';
import { Provider } from "react-redux";
import Theme from './theme/Theme'
//import { HelmetProvider, Helmet } from "react-helmet-async";

import { store } from "./redux/store";

import { AuthProvider } from "./contexts/FirebaseAuthContext";

function App() {
  console.log("in app")

  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Theme>
            <MyRoutes />
          </Theme>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;

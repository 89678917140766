import React from "react";
import "./Select.css";

function Select({ states, placeholder, handleStateChange }) {
  return (
    <div>
      <label className="SelectLabel" htmlFor="state">
        {placeholder}
      </label>
      <select value="" onChange={handleStateChange}>
      {states.map((state) => (
        <option key={state} value={state}>
          {state}
        </option>
      ))}
    </select>
    </div>
  );
}

export default Select;

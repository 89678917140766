import React from "react";

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
const HeaderMenu =()=> {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 250)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)    
}
  })
  const handleClick = () => {
    if (open) {
      setOpen(false);
    }
    else {
      setOpen(true);
    }
  }
  if (dimensions.width < 700 && !open) {
    return (
      <div style={{padding: 10, paddingTop: 25, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <div className="TitleHeaderBox">
          <a href={"/"}><h1 className="HeaderText">DigmTime</h1></a>
        </div>
        <img className="unstyledimg" src={"Hamburger_icon_white.svg"} alt="" onClick={handleClick}></img>
      </div>
    )
  }
  else if (dimensions.width < 700 && open) {
    return (
    <div className="LinksDropDownBox">
      <div style={{padding: 10, paddingTop: 25, display: "flex", alignItems: "center", justifyContent: "space-between", borderBottomWidth: '2px', borderBottomStyle: 'solid', borderColor: '#7ad0e1'}}>
        <div className="TitleHeaderBox">
              <a href={"/"}><h1 className="HeaderText">DigmTime</h1></a>
        </div>
        <div>
          <img className="unstyledimg" src={"Hamburger_icon_white.svg"} alt="" onClick={handleClick}></img>
        </div>
      </div>

    <div className="LinkHeaderBox" style={{borderBottomWidth: '1px', borderBottomStyle: 'solid', borderColor: '#7ad0e1'}}>
      <a href={"/"}><h2 className="HeaderLinkText">About</h2></a>
    </div>

    <div className="LinkHeaderBox" style={{borderBottomWidth: '1px', borderBottomStyle: 'solid', borderColor: '#7ad0e1'}}>
      <a href={"/mygrind"}><h2 className="HeaderLinkText">My Grind</h2></a>
    </div>

    <div className="LinkHeaderBox" style={{borderBottomWidth: '1px', borderBottomStyle: 'solid', borderColor: '#7ad0e1'}}>
    <a href={"/greenville"}><h2 className="HeaderLinkText">Our Grind</h2></a>
    </div>

    <div className="LinkHeaderBox" style={{borderBottomWidth: '1px', borderBottomStyle: 'solid', borderColor: '#7ad0e1'}}>
      <a href={"/signup"}><h2 className="HeaderLinkText">LoginFlow</h2></a>
    </div>

  </div>
      
    )
  }
  return (    
    //turn headers into links  
      <div style={{padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <div>
          <div className="TitleHeaderBox">
            <a href={"/"}><h1 className="HeaderText">DigmTime</h1></a>
          </div>
        </div>
        <div className="LinksHeaderBox">
          <div className="LinkHeaderBox" >
            <a href={"/"}><h2 className="HeaderLinkText">About</h2></a>
          </div>
          <div className="LinkHeaderBox">
          <a href={"/mygrind"}><h2 className="HeaderLinkText">My Grind</h2></a>
          </div>
          <div className="LinkHeaderBox">
            <a href={"/greenville"}><h2 className="HeaderLinkText">Our Grind</h2></a>
          </div>
          <div className="LinkHeaderBox">
            <a href={"signup"}><h2 className="HeaderLinkText">LoginFlow</h2></a>
          </div>
        </div>
      </div>
  )
};

export default HeaderMenu;
import React from 'react';
import Header from "../components/header/Header";

const DefaultLayout = ({children}) => {
  console.log("default layout")
  return( 
    <div>
      <Header/>
      {children}
    </div>
  )
}

export default DefaultLayout;
import React from "react";
import '../App.css';
import SignUpButton from '../components/buttons/SignUpButton';

const Landing =()=>{
  console.log("in landing")
  let  windowWidth = window.innerWidth;
  let imageWidth = windowWidth/3;
  if (imageWidth < 500) {
    imageWidth = windowWidth- 70;
  }
  return (
    <div>

      <div class="container" style={{display: 'flex', justifyContent: 'center', alignItems: "center", height: '100px', flexDirection: "column", marginBottom: 70, marginTop: 100}}>
        <p className="BodyTextEmphasis" style={{textAlign: 'center', fontSize: '1.4em', fontStyle: 'italic'}}>"Never doubt that a small group of thoughtful, 
          commited citizens can change the world"</p>
        <p className="BodyText" style={{marginTop: 10, marginLeft: '4em', fontSize: '1.70em', fontStyle: 'italic'}}> Margaret Mead </p>
      </div>

      <h1 className="SectionHeaderText" style={{marginBottom: 20, marginTop:100}}>Welcome to DigmTime!</h1>
      <p className="BodyText" style={{marginLeft: '2em', marginRight: '2em', marginBottom: 70, display: 'inline-block'}}> We are thrilled to invite you to join our vibrant and nurturing community, 
        where the seeds of growth and prosperity are sown. At <p className="BodyTextEmphasis" style={{display: 'inline-block'}}> DigmTime, </p>we believe in the incredible power of togetherness, and our mission is to create an inclusive space where 
        individuals from all walks of life can come together to thrive and flourish. Whether you're an aspiring entrepreneur, a seasoned professional, or simply someone seeking personal growth,
        our community is designed to inspire, support, and empower you on your journey. Step inside and discover a place where connections are forged, dreams take flight, 
        and the possibilities for success are limitless. Embrace the spirit of collaboration, embark on a transformative adventure, and let<p className="BodyTextEmphasis" style={{display: 'inline-block'}}> DigmTime </p> 
        be your catalyst for reaching new heights.
       </p>

      <SignUpButton />

      <h1 className="SectionHeaderText" style={{marginTop: 50, marginBottom: 0, fontSize: '2.75em'}}>What We Value</h1>
      <div class="container" className="GridLayout">
        <div className="GridItem">
          <p className="BodyText"><p className="BodyTextEmphasis"> ...culture </p> and </p>
          <p className="BodyText">building stronger <p className="BodyTextEmphasis"> communities </p></p>
          <img className="BlueImage" src={"soyjess.jpg"} alt="" width={imageWidth}/>
        </div> 
        <div className="GridItem"> 
          <p className="BodyText">...promoting  <p className="BodyTextEmphasis">positive</p> atmospheres</p>          
          <p className="BodyText"> that encourage <p className="BodyTextEmphasis">growth</p></p>
          <img className="BlueImage" src={"books.jpg"} alt="" width={imageWidth}/>  
        </div>
        <div className="GridItem" style={{justifyContent: "flex-start", marginTop: 20}}> 
          <p className="BodyText"><p className="BodyTextEmphasis">...living</p></p> 
          <p className="BodyText">life to the <p className="BodyTextEmphasis">fullest!</p></p>
          <img className="BlueImage" src={"neal.jpg"} alt="" width={imageWidth}/>
        </div>      
      </div>
      <h1 className="SectionHeaderText" style={{marginTop: 50, marginBottom: 10, fontSize: '2.75em'}}>Questions?</h1>

    </div>
    
  )
};

export default Landing;

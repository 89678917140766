import React from "react";
import {Route, Routes} from 'react-router-dom';

import DefaultLayout from './layouts/DefaultLayout';

import Landing from "./pages/Landing";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";


function MyRoutes() {
  return (
    <Routes>  
      <Route path="/" element={<DefaultLayout><Landing /></DefaultLayout>}/>
      <Route path="/signup" element={<DefaultLayout><SignUp /></DefaultLayout>}/>
      <Route path="/login" element={<DefaultLayout><Login /></DefaultLayout>}/>
      <Route path="/mygrind" element={<DefaultLayout></DefaultLayout>}/>
      <Route path="/greenville" element={<DefaultLayout></DefaultLayout>}/>
    </Routes>
  )
}

export default MyRoutes;

import React from 'react';
import '../App.css';

const Theme = ({children}) => {
  return (
    <div className="DefaultBackground">
      {children}
    </div>
  )

}

export default Theme;
import React, {useState} from "react";
import "./TextArea.css";

function TextArea({ placeholder, onTextChange }) {

  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    onTextChange(placeholder, newText);
  };

  return (
    <div>
      <label className="TextAreaLabel" htmlFor="textarea">{placeholder}</label>
      <textarea id="textarea" className="TextArea TextAreaText" value={text} onChange={handleTextChange} />
    </div>
  );
}

export default TextArea;